import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Login from "../Auth/login";
import * as BsIcons from "react-icons/bs";
import TabPanel from "../Auth/TabPanel";
import * as BiIcons from "react-icons/bi";
import { HiChevronDown } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { getUserNotification, getUserProfile } from "../Redux/Slices/ProfileSlice";
import { userRole } from "../JsonData/json";
import CommonModal from "./CommonModal";


function Navbar() {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserProfile = localStorage.getItem("ProfileImg");
  const userRole = localStorage.getItem('userRole')
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  const Title = "Get Subscription"
  const Description = "To Post Property You Need To purchase Subscription"

  const User = useSelector((state) => state.userProfile.profile);
  console.log("selector", User);

  const [SubscriptionModal,setSubscriptionModal]=useState(false)
  const [prevNotificationCount, setPrevNotificationCount] = useState(0);
  const UserNotification = useSelector( (state) => state.userProfile.Notification);


  useEffect(() => {
    const userData = {
      headers: {
        authorization: token,
      },
    };

    dispatch(getUserProfile({ id: userId, data: userData }));
  }, [userId, token]);


  useEffect(() => {
    setPrevNotificationCount(UserNotification.length);

    const userData = {
      headers: {
        authorization: token,
      },
    };

    dispatch(getUserNotification({ id: userId, data: userData }));

    // Update prevNotificationCount after fetching new notifications
    setPrevNotificationCount(UserNotification.length);
  }, [userId, token, dispatch]);

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const OfcState = {
    param1: "office space",
    param2: "Rent",
  };

  const flatState = {
    param1: " Flat/ Apartment",
    param2: "Rent",
  };

  const redirect = () =>{
    navigate("/PropertyPlans")
    setSubscriptionModal(false)
  }

  const postProperty =()=>{
    
    User?.Userdata?.postpropertycount == 0 ?
      setSubscriptionModal(true)
      :
      navigate("/AddProperty")

  }


  return (
    <header class="main-header  navbar-light header-sticky header-sticky-smart header-mobile-lg mb-7">
      <div class="sticky-area">
      {SubscriptionModal &&  <CommonModal Title={Title} Description={Description} setModal={setSubscriptionModal} ButtonName={"Subscribe"} Submit={redirect}/>}
        <div class="container">
          <nav class="navbar navbar-expand-lg   fixed-top pt-0 pt-lg-2 "> 
            <Link to="/" class="navbar-brand pt-0 pt-lg-1">
              <img
                src="../images/logo-property-nexus-removebg-preview.png"
                alt="Property Nexus"
                height={170}
                width={200}
              />
            </Link>
           
            <div className="d-flex d-lg-none">
            <button class="navbar-toggler bg-white " type="button" data-bs-toggle="collapse" data-bs-target="#primaryMenu01" aria-controls="primaryMenu01" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          {token != null ? null : (
                  <li class="nav-item ">
                    <Link
                      to="/Login"
                      class="nav-link text-white px-2"
                      data-toggle="modal"
                      href="#login-register-modal"
                    >
                      Sign In
                    </Link>
                  </li>
                )}
                {token != null ? (
                  <div class="dropdown profile-drop">
                    <div className="dropdown-toggle">
                      <img
                        class="profile-img border rounded-circle ms-3 "
                        src={User?.ProfileImage ? User?.ProfileImage : "https://www.pngitem.com/pimgs/m/661-6619328_default-avatar-png-blank-person-transparent-png.png"}
                        alt=""
                      />
                    </div>
                    <div class="dropdown-menu profile-drop-menu">
                      <div class="d-flex">
                        <div class="pro-img">
                          <img
                            class="prof-img border rounded-circle ms-3"
                            src={User?.ProfileImage ? User?.ProfileImage : "https://www.pngitem.com/pimgs/m/661-6619328_default-avatar-png-blank-person-transparent-png.png"}
                            alt=""
                          />
                        </div>
                        <div class="pro-details">
                          <p>
                            {User?.Userdata?.firstname}{" "}
                            {User?.Userdata?.lastname}
                          </p>
                          <p>Free Member</p>
                        </div>
                      </div>
                      <div class="dropdown-divider"></div>
                      <Link
                        to="/UserDashboard"
                        class="dropdown-item profile-drop-item"
                      >
                        User Dashboard
                      </Link>
                      <Link
                        to="/MyProfile"
                        class="dropdown-item profile-drop-item"
                      >
                        My Profile 
                      </Link>
                      <Link
                        to="/MyProfile"
                         class="dropdown-item profile-drop-item">
                        Change Password
                      </Link>
                      <Link
                        to="/MyPackages"
                        class="dropdown-item profile-drop-item"
                      >
                        My Packages
                      </Link>
                      <a
                        onClick={() => logout()}
                        class="dropdown-item profile-drop-item"
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                ) : null}
            </div>
            <div
              class="collapse navbar-collapse mobile-togle-click mt-3 mt-lg-0 mx-auto flex-grow-0"
              id="primaryMenu01"
            >
              <ul class="navbar-nav hover-menu  main-menu px-0 mx-lg-n4">
                <li class="nav-item dropdown">
                  <a
                    class=" nav-link dropdown-toggle p-0"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded=""
                  >
                    Buy
                    <span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu " style={{width:'830px'}}>
                    <div class="row">
                    <div class="col-lg-2 " > 
                        <p className="text-primary pl-3">Popular Choices</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Ready to Move",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Ready to Move
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Owner Properties",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Owner Properties
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Budget Homes", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Budget Homes
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Premium Homes", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Premium Homes
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Newly Launched", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Newly Launched
                          </Link>
                        </li>
                      </div>
                      <div class="col-lg-3 ">
                        <p className="text-primary pl-3">Property Types</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Flats in Pune",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Flats in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " House for sale in Pune",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            House for sale in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Villa in Pune", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Villa in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Plot in Pune", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Plot in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Office Space in Pune", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Office Space in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Commercial Space in..", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Commercial Space in
                          </Link>
                        </li>
                      </div>
                      <div class="col-lg-2 ">
                        <p className="text-primary pl-3">Budget</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Under ₹ 50 Lac",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Under ₹ 50 Lac
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " ₹ 50 Lac - ₹ 1 Cr",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            ₹ 50 Lac - ₹ 1 Cr
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "₹ 1 Cr - ₹ 1.5 Cr", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            ₹ 1 Cr - ₹ 1.5 Cr
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Above ₹ 1.5 Cr", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Above ₹ 1.5 Cr
                          </Link>
                        </li>
                       
                      </div>
                      <div class="col-lg-2 ">
                        <p className="text-primary pl-3">Explore</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Localities in Pune",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Localities in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Projects in Pune",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            Projects in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Investment Hotspot", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Investment Hotspot
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Find an Agent", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Find an Agent
                          </Link>
                        </li>
                        
                      </div>
                      <div class="col-lg-2 ">
                        <p className="text-primary pl-3">Buying Tools</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Propworth",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Propworth
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Rates & Trends",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            Rates & Trends
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Buy vs Rent", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Buy vs Rent
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Tips and Guides", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Tips and Guides
                          </Link>
                        </li>
                      </div>
                      {/* <div class="col-md-6 " >
                        <p className="text-primary ml-4">Residential</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Flat/ Apartment",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Flat/ Apartment
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Independant House / Villas",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Independant House / Villas
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "office space", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Office spaces
                          </Link>
                        </li>
                      </div>
                      <div class="col-md-6 ">
                        <p className="text-primary">Commercial</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Flat/ Apartment",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Shop
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Independant House / Villas",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            Plot land
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "office space", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Office spaces
                          </Link>
                        </li>
                      </div> */}
                    </div>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle  nav-buy"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Rent
                    <span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu " style={{width:'700px'}}>
                    <div class="row">
                    <div class="col-lg-3 " >
                        <p className="text-primary pl-3">Popular Choices</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Ready to Move",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Ready to Move
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Owner Properties",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Owner Properties
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Budget Homes", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Budget Homes
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Premium Homes", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Premium Homes
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Newly Launched", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Newly Launched
                          </Link>
                        </li>
                      </div>
                      <div class="col-lg-3 ">
                        <p className="text-primary pl-3">Property Types</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Flats in Pune",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Flats in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " House for sale in Pune",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            House for sale in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Villa in Pune", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Villa in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Plot in Pune", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Plot in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Office Space in Pune", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Office Space in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Commercial Space in..", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Commercial Space in
                          </Link>
                        </li>
                      </div>
                      <div class="col-lg-2 ">
                        <p className="text-primary pl-3">Budget</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Under ₹ 50 Lac",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Under ₹ 50 Lac
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " ₹ 50 Lac - ₹ 1 Cr",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            ₹ 50 Lac - ₹ 1 Cr
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "₹ 1 Cr - ₹ 1.5 Cr", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            ₹ 1 Cr - ₹ 1.5 Cr
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Above ₹ 1.5 Cr", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Above ₹ 1.5 Cr
                          </Link>
                        </li>
                       
                      </div>
                      <div class="col-lg-2 ">
                        <p className="text-primary pl-3">Explore</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Localities in Pune",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Localities in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Projects in Pune",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            Projects in Pune
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Investment Hotspot", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Investment Hotspot
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Find an Agent", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Find an Agent
                          </Link>
                        </li>
                        
                      </div>
                    </div>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle nav-buy"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Sell
                    <span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu " style={{width:'600px'}}>
                    <div class="row">
                    <div class="col-lg-4 " >
                        <p className="text-primary pl-3">For Owner</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Post Property",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Post Property
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " My Dashboard",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            My Dashboard
                          </Link>
                        </li>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Sell / Rent Ad Packages", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Sell / Rent Ad Packages
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "+91 9870 260 930 / Email Us", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            +91 9870 260 930 / Email Us
                          </Link>
                        </li>
                        
                      </div>
                      <div class="col-lg-4 ">
                        <p className="text-primary pl-3">For Agent & Builder</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " My Dashboard",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            My Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Ad Packages",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            Ad Packages
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "iAdvantage", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            iAdvantage
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Developer Lounge", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Developer Lounge
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Sales Enquiry", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Sales Enquiry
                          </Link>
                        </li>
                      </div>
                      <div class="col-lg-4 ">
                        <p className="text-primary pl-3">Selling Tools</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Property Valuation",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Property Valuation
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Find an Agent",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            Find an Agent
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Rates & Trends", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Rates & Trends
                          </Link>
                        </li>
                      </div>
                      
                    </div>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle nav-buy"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Property Services
                  </a>
                  <ul class="dropdown-menu " style={{width:'600px'}}>
                    <div class="row">
                    <div class="col-lg-4 " >
                        <p className="text-primary pl-3">Rent Services</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Rent Agreement",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Rent Agreement
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Generate Rent Receipt",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Generate Rent Receipt
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Tenant Verification", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Tenant Verification
                          </Link>
                        </li>
                        
                      </div>
                      <div class="col-lg-4 ">
                        <p className="text-primary pl-3">Buy / Sell Services</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Home Interiors",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Home Interiors
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: "Property Valuation",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                           Property Valuation
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Legal Title Check", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Legal Title Check
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Property Lawyers", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Property Lawyers
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Home Inspection", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Home Inspection
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{ param1: "Vastu", param2: "Buy" }}
                            class="dropdown-item"
                            href="#"
                          >
                            Vastu
                          </Link>
                        </li>
                      </div>
                      <div class="col-lg-4 ">
                        <p className="text-primary pl-3">Home Services</p>
                        <hr
                          className="dropdown-divider"
                          style={{ color: "black" }}
                        />
                        <li>
                          <Link
                            to="/PropertyListHome"
                            state={{
                              param1: " Packers & Movers",
                              param2: "Buy",
                            }}
                            class="dropdown-item"
                            href="#"
                          >
                            {" "}
                            Packers & Movers
                          </Link>
                        </li>
                        
                      </div>
                      
                    </div>
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-buy" href="#">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div class="d-none d-lg-block">
              <ul class="navbar-nav flex-row justify-content-lg-end d-flex flex-wrap text-body mt-0 py-2">
                    <Link to="/home/propertyAnalysis" class="btn btn-primary btn-md me-2">
                      <span>Analysis</span>
                    </Link>
                {token != null ? null : (
                  <li class="nav-item">
                    <Link
                      to="/Login"
                      class="nav-link  px-2"
                      data-toggle="modal"
                      href="#login-register-modal"
                    >
                      Sign In
                    </Link>
                  </li>
                )}
                {userRole === "user" ?
              
                  <li class="nav-item ml-auto w-100 w-sm-auto">
                    <button
                      onClick={()=>postProperty()}
                      class="btn btn-primary btn-sm p-2 "
                    > 
                      Post Property
                    </button>
                  </li>
                  :
                  null
                }
                {token != null ? (
                  <div class="dropdown profile-drop">
                    <div className="dropdown-toggle">
                      <img
                        class="profile-img border rounded-circle ms-3 "
                        src={User?.ProfileImage ? User?.ProfileImage : "https://www.pngitem.com/pimgs/m/661-6619328_default-avatar-png-blank-person-transparent-png.png"}
                        alt=""
                      />
                    </div>
                    <div class="dropdown-menu profile-drop-menu">
                      <div class="d-flex">
                        <div class="pro-img">
                          <img
                            class="prof-img border rounded-circle ms-3"
                            src={User?.ProfileImage ? User?.ProfileImage : "https://www.pngitem.com/pimgs/m/661-6619328_default-avatar-png-blank-person-transparent-png.png"}
                            alt=""
                          />
                        </div>
                        <div class="pro-details">
                          <p>
                            {User?.Userdata?.firstname}{" "}
                            {User?.Userdata?.lastname}
                          </p>
                          <p>Free Member</p>
                        </div>
                      </div>
                      <div class="dropdown-divider"></div>
                      <Link
                        to="/UserDashboard"
                        class="dropdown-item profile-drop-item"
                      >
                        User Dashboard
                      </Link>
                      <Link
                        to="/MyProfile"
                        class="dropdown-item profile-drop-item"
                      >
                        My Profile 
                      </Link>
                      <Link
                        to="/MyProfile"
                         class="dropdown-item profile-drop-item">
                        Change Password
                      </Link>
                      <Link
                        to="/MyPackages"
                        class="dropdown-item profile-drop-item"
                      >
                        My Packages
                      </Link>
                      <a
                        onClick={() => logout()}
                        class="dropdown-item profile-drop-item"
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                ) : null}

                <div class="dropdown no-caret  px-sm-2 d-flex align-items-center justify-content-end notice">
                  <Link
                    to="/Notification"
                    state={{ notificationData: UserNotification }}
                    class="dropdown-toggle text-heading fs-25 font-weight-500 lh-1"
                    data-toggle="dropdown"
                  >
                    <BsIcons.BsBell color="white" />

                    {UserNotification.length > prevNotificationCount && (
                      <span className="badge badge-success badge-circle badge-absolute font-weight-bold fs-7">
                        {UserNotification.length - prevNotificationCount}
                      </span>
                    )}
                  </Link>
                  {/* <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div> */}
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
