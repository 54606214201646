import React, { useState } from "react";

const EMICalculator = () => {
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [tenure, setTenure] = useState(5);
  const [interestRate, setInterestRate] = useState(7.5);

  // EMI Calculation
  const calculateEMI = () => {
    const r = interestRate / 12 / 100;
    const n = tenure * 12;
    const emi = loanAmount * r * (Math.pow(1 + r, n) / (Math.pow(1 + r, n) - 1));
    return emi.toFixed(2);
  };

  return (
    <div className="mt-3">
      <h3>EMI Calculator</h3>
      <div className="row">
        <div className="col-md-6">
          <label>Loan Amount</label>
          <input
            type="range"
            className="form-range"
            min="100000"
            max="10000000"
            step="100000"
            value={loanAmount}
            onChange={(e) => setLoanAmount(Number(e.target.value))}
          />
          <div>₹ {loanAmount.toLocaleString()}</div>
        </div>
        <div className="col-md-6">
          <label>Tenure (Years)</label>
          <input
            type="range"
            className="form-range"
            min="1"
            max="30"
            value={tenure}
            onChange={(e) => setTenure(Number(e.target.value))}
          />
          <div>{tenure} years</div>
        </div>
        <div className="col-md-6">
          <label>Interest Rate (% P.A.)</label>
          <input
            type="range"
            className="form-range"
            min="1"
            max="15"
            step="0.1"
            value={interestRate}
            onChange={(e) => setInterestRate(Number(e.target.value))}
          />
          <div>{interestRate} %</div>
        </div>
      </div>
      <div className="mt-4">
        <h5>Monthly EMI: ₹ {calculateEMI()}</h5>
      </div>
    </div>
  );
};

export default EMICalculator;
