import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBottomAdvertiseData, getTopAdvertiseData } from "../../Redux/Slices/AdvertiseSlice";
import { useNavigate } from "react-router-dom";
import AdContactModal from "./ContactModal";


function HomePageAdvertiseBottom() {
  const BottomAdRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserId = localStorage.getItem("userId");

  const Properties = useSelector((state) => state.add.TopAddList);
  console.log("AdvertiseProperties", Properties);

  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [viewedAdId, setViewedAdId] = useState(null); // Track the currently viewed ad ID

  console.log("viewedAdId", viewedAdId);

  // Fetch new ads every 25 seconds
  useEffect(() => {
    const formData = new FormData();
    formData.append("adFormat", "image");
    formData.append("pageToShow", "homepage");
    formData.append("adPosition", "bottom");

    const fetchAdvertiseData = () => {
      dispatch(getBottomAdvertiseData({ data: formData }));
      setViewedAdId(null); // Reset the viewed status for the new ad
    };

    fetchAdvertiseData();

    const interval = setInterval(fetchAdvertiseData, 25000);
    return () => clearInterval(interval);
  }, [dispatch]);

  const redirectToAd = (link,AdId) => {

    window.open(link, "_blank");

    const data = {
      method: "PUT",
    };
    
    fetch(`https://mloneusk.lol/api/form/increaseresult/${AdId}`, data)
      .then((response) => response.json())
      .then((data) => {
        console.log("click on AD", data);
      })
      .catch((err) => console.log(err));
};

  const closeModal = () => {
    setShowModal(false);
    setPhoneNumber("");
  };

  // Handle view count API call
  const handleAdView = () => {
    if (Properties?.adId && viewedAdId !== Properties.adId) {
      console.log("Ad is now visible. Calling view count API...");

      fetch(`https://mloneusk.lol/api/stat/add?userId=${UserId}&adId=${Properties?.adId}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => console.log("View count API response:", data))
        .catch((err) => console.log("View count API error:", err));

      setViewedAdId(Properties.adId); // Mark the current ad as viewed
    }
  };

  // Set up Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          handleAdView();
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the ad is visible
      }
    );

    if (BottomAdRef.current) {
      observer.observe(BottomAdRef.current);
    }

    return () => {
      if (BottomAdRef.current) {
        observer.unobserve(BottomAdRef.current);
      }
    };
  }, [Properties, viewedAdId]); // Dependencies include Properties and viewedAdId

  return (
    <div className="container my-5 position-relative" ref={BottomAdRef}>
      {showModal && (
        <AdContactModal closeModal={closeModal} MobileNumber={phoneNumber} />
      )}
      <div className="property-card position-relative">
        <div className="image-wrapper">
          <img
            src={Properties?.mediaUrl} // Replace with your actual image URL
            alt="Property"
            className="property-image w-100"
          />
        </div>

        {/* Content Section */}
        <div className="content-overlay">
          <h5 className="fw-bold text-white">{Properties?.headLine}</h5>
          <p className="text-white">
            {Properties?.description?.length > 100
              ? `${Properties.description.slice(0, 100)}...`
              : Properties?.description}
          </p>
          <a
            onClick={() => redirectToAd(Properties?.siteLink,Properties?.adId)}
            className="explore-button"
          >
            Explore Now →
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomePageAdvertiseBottom;