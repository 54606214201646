import React, { useRef, useState } from 'react'
import Slider from "react-slick";
import { FaPhone } from "react-icons/fa6";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCenterAdvertiseData, getCenterAdvertiseGIFData } from "../../Redux/Slices/AdvertiseSlice";
import { useNavigate } from "react-router-dom";
import AdContactModal from './ContactModal';


const HomePageAdvertiseMiddle = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        arrows: true,
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const imageRef = useRef(null);
    const videoWatchedRef = useRef(false);
    const UserId = localStorage.getItem("userId");
    const CenterPropertiesVideos = useSelector((state) => state.add.CenterAddList);
    const CenterPropertiesGif = useSelector((state) => state.add.CenterGIFAddList);

    const [showModal, setShowModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [viewedVideoAdId, setViewedVideoAdId] = useState(null);
    const [viewedImageAdId, setViewedImageAdId] = useState(null);


     // Fetch video and GIF ads periodically
     const fetchVideoAndGIFAds = () => {
        // Video ads
        const formData = new FormData();
        formData.append("pageToShow", "homepage");
        formData.append("adFormat", "video");
        formData.append("adPosition", "center");
        dispatch(getCenterAdvertiseData({ data: formData }));

        // GIF ads
        const formData1 = new FormData();
        formData1.append("pageToShow", "homepage");
        formData1.append("adFormat", "gif");
        formData1.append("adPosition", "center");
        dispatch(getCenterAdvertiseGIFData({ data: formData1 }));

        videoWatchedRef.current = false; 
    };

    useEffect(() => {
        // Initial fetch
        fetchVideoAndGIFAds();
    
        // Set interval for periodic updates every 25 seconds
        const interval = setInterval(fetchVideoAndGIFAds, 25000);
    
        return () => clearInterval(interval); // Cleanup
      }, [dispatch]);
    
      // Function to handle view count for ads
      const handleAdView = (adId, format) => {
        if (!adId) return;
    
        // Check and prevent duplicate view count hits
        if (
          (format === "video" && adId === viewedVideoAdId) ||
          (format === "gif" && adId === viewedImageAdId)
        ) {
          return;
        }
    
        console.log("Ad is now visible. Calling view count API...");
    
        // API call to send view count
        fetch(`https://mloneusk.lol/api/stat/add?userId=${UserId}&adId=${adId}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => console.log(`View count API response for ${format}:`, data))
          .catch((err) => console.log(`View count API error for ${format}:`, err));
    
        // Update viewed IDs to prevent duplicate calls
        if (format === "video") setViewedVideoAdId(adId);
        if (format === "gif") setViewedImageAdId(adId);
      };
    
      // Trigger view count for video after 20 seconds
      const handleVideoTimeUpdate = (e) => {
        const video = e.target;
        if (video.currentTime >= 20) {
          handleAdView(CenterPropertiesVideos?.adId, "video");
        }
      };
    

      // Trigger view count for image when it becomes visible
      useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                handleAdView(CenterPropertiesGif?.adId, "gif");
                observer.unobserve(entry.target);
              }
            });
          },
          { threshold: 0.5 }
        );
    
        if (imageRef.current) observer.observe(imageRef.current);
    
        return () => observer.disconnect();
      }, [CenterPropertiesGif?.adId]);


   const redirectToAd = (link,AdId) => {

          window.open(link, "_blank");
  
          const data = {
            method: "PUT",
          };
          
          fetch(`https://mloneusk.lol/api/form/increaseresult/${AdId}`, data)
            .then((response) => response.json())
            .then((data) => {
              console.log("click on AD", data);
            })
            .catch((err) => console.log(err));
      };
    
      const closeModal = () => {  
        setShowModal(false);
        setPhoneNumber("");
      };
    

    return (
        <div className="container">
             {showModal && (
        <AdContactModal closeModal={closeModal} MobileNumber={phoneNumber} />
      )}
        <div className="row">
            {/* Card 1 */}
            <div className="col-md-6 d-flex align-items-stretch">
                <div className="card-wrapper w-100 h-100 d-flex flex-column">
                    <div className="property-card d-flex flex-column h-100">
                    <video
                                ref={videoRef}
                                src={CenterPropertiesVideos?.mediaUrl}
                                controls
                                playsInline
                                autoPlay
                                muted
                                onTimeUpdate={handleVideoTimeUpdate}
                                style={{
                                    width: "100%",
                                    height: "50%",
                                    objectFit: "cover",
                                }}
                            />
                        <div className="property-content  flex-grow-1">
                            <div className="d-flex ">
                                <h5>{CenterPropertiesVideos.headLine}</h5>
                            </div>
                        </div>
                        <div className="property-footer  d-flex justify-content-between align-items-center">
                            <span>
                                Interested in this project by{" "}
                                <strong>{CenterPropertiesVideos.interestedBy}</strong>?
                            </span>
                            <button onClick={()=>redirectToAd(CenterPropertiesVideos?.siteLink,CenterPropertiesVideos?.adId)} className="btn btn-primary">
                                Explore
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Card 2 */}
            <div className="col-md-6 d-flex align-items-stretch">
                <div className="card-wrapper w-100 h-100 d-flex flex-column">
                    <div className="property-card d-flex flex-column h-100">
                        <img
                            ref={imageRef} 
                            src={CenterPropertiesGif.mediaUrl}
                            alt={CenterPropertiesGif.headLine}
                            className="property-image"
                            style={{
                                width: "100%",
                                height: "50%", // Ensure the image takes half height
                                objectFit: "cover",
                                borderRadius: "5px",
                            }}
                        />
                        <div className="property-content p-3 flex-grow-1">
                            <div className="d-flex">
                                <h5>{CenterPropertiesGif.headLine}</h5>
                                {/* <p>{CenterPropertiesGif.location}</p> */}
                            </div>
                        </div>
                        <div className="property-footer p-3 d-flex justify-content-between align-items-center">
                            <span>
                                Interested in this project by{" "}
                                <strong>{CenterPropertiesGif.interestedBy}</strong>?
                            </span>
                            <button onClick={()=>redirectToAd(CenterPropertiesVideos?.siteLink,CenterPropertiesVideos?.adId)} className="btn btn-primary">
                                Explore
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    );
};

export default HomePageAdvertiseMiddle;
