import React, { useState } from "react";
import { toast } from "react-toastify";


const CapitalGainsTaxCalculator = () => {

  const [salePrice, setSalePrice] = useState("");
  const [transferCost, setTransferCost] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [improvementCost, setImprovementCost] = useState("");
  const [purchaseYear, setPurchaseYear] = useState("");
  const [improvementYear, setImprovementYear] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [result, setResult] = useState(null);


  const CII = {
    2001: 100,
    2002: 105,
    2003: 109,
    2004: 113,
    2005: 117,
    2006: 122,
    2007: 129,
    2008: 137,
    2009: 148,
    2010: 167,
    2011: 184,
    2012: 200,
    2013: 220,
    2014: 240,
    2015: 254,
    2016: 264,
    2017: 272,
    2018: 280,
    2019: 289,
    2020: 301,
    2021: 317,
    2022: 331,
    2023: 348,
    2024: 363,
  };

  const handleCalculate = () => {

    if (
      salePrice &&
      transferCost &&
      purchasePrice &&
      improvementCost &&
      purchaseYear &&
      improvementYear &&
      taxRate
    ) {
      const saleYearCII = CII[2024]; // Assuming sale is in 2024
      const purchaseYearCII = CII[purchaseYear];
      const improvementYearCII = CII[improvementYear];

      const indexedAcquisitionCost = (purchasePrice * saleYearCII) / purchaseYearCII;
      const indexedImprovementCost = (improvementCost * saleYearCII) / improvementYearCII;
      
      const capitalGains = (salePrice - transferCost - indexedAcquisitionCost - indexedImprovementCost) * (taxRate / 100);
      setResult(capitalGains.toFixed(2));

    } else {
      toast.error("Please fill all fields!");
    }
  };

  return (
    <div className="container my-4">
      <h2>Capital Gains Tax Calculator</h2>
      <div className="row g-3">
        <div className="col-md-6">
          <label>Sale Price</label>
          <input
            type="number"
            className="form-control"
            value={salePrice}
            onChange={(e) => setSalePrice(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Cost of Transfer</label>
          <input
            type="number"
            className="form-control"
            value={transferCost}
            onChange={(e) => setTransferCost(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Purchase Price</label>
          <input
            type="number"
            className="form-control"
            value={purchasePrice}
            onChange={(e) => setPurchasePrice(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Improvement Cost</label>
          <input
            type="number"
            className="form-control"
            value={improvementCost}
            onChange={(e) => setImprovementCost(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Purchase Year</label>
          <select
            className="form-select"
            value={purchaseYear}
            onChange={(e) => setPurchaseYear(e.target.value)}
          >
            <option value="">Select Year</option>
            {Object.keys(CII).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <label>Sale Year</label>
          <select
            className="form-select"
            value={improvementYear}
            onChange={(e) => setImprovementYear(e.target.value)}
          >
            <option value="">Select Year</option>
            {Object.keys(CII).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <label>Tax Rate (%)</label>
          <input
            type="number"
            className="form-control"
            value={taxRate}
            onChange={(e) => setTaxRate(e.target.value)}
          />
        </div>
        <div className="col-md-6">
        <label></label>
        {result !== null && (
          <div className="col-12">
            <h4>Capital Gains Tax: ₹{result}</h4>
          </div>
        )}
        </div>
        <div className="col-12">
          <button className="btn btn-primary" onClick={handleCalculate}>
            Calculate
          </button>
        </div>
        
      </div>
    </div>
  );
};

export default CapitalGainsTaxCalculator;
