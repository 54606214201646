import React from "react";
import { lazy, Suspense } from "react";
import MainLayout from "../Layout/MainLayout";
import AuthLayout from "../Layout/AuthLayout";
import { useRoutes } from "react-router-dom";
import DashboardLayout from "../Layout/DashboardLayout";
import Login from "../Auth/login";
import Home from "../Common/home";
import Footer from "../Common/footer";

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>);

const User = Loader(lazy(() => import("../Auth/User")));
const Registration = Loader(lazy(() => import("../Auth/Registration")));
const ForgetPassword = Loader(lazy(() => import("../Auth/ForgetPassword")));
// const Profile = Loader(lazy(() => import("../Component/Profile/Profile")));
const MobilenoVerification = Loader(lazy(() => import("../Auth/MobilenoVerification")));
const TabPanel = Loader(lazy(() => import("../Auth/TabPanel")));
const PropertyListHome = Loader(lazy(() => import("../Component/Properties/PropertyListHome")));
const UserDashboard = Loader(lazy(() => import("../UserDashboard/Dashboard/Dashboard")));
const SelectPropertyFor = Loader(lazy(() =>import("../UserDashboard/Properties/AddPropertyForm/SelectPropertyFor")));
const SelectPropertyType = Loader(lazy(() => import("../UserDashboard/Properties/SelectPropertyType")));
const AddProperty = Loader(lazy(() => import("../UserDashboard/Properties/AddPropertyForm/AddProperty")));
const PropertyMedia = Loader(lazy(() =>import("../UserDashboard/Properties/AddPropertyForm/PropertyMedia")));
const OwnerPropertiesScreen = Loader(lazy(() => import("../UserDashboard/Properties/My Properties/Index")));
const OwnerBookedPropertyDetails = Loader(lazy(() => import("../UserDashboard/User/Seller/MyBookedProperties/index")));
const ViewPropertyDetails = Loader(lazy(() => import("../UserDashboard/Properties/ViewPropertyDetails")));
const PropertyVerification = Loader(lazy(() => import("../UserDashboard/Properties/PropertyVerification")));
const FavoritesProperties = Loader(lazy(() => import("../UserDashboard/Properties/FavoritesProperties")));
const MyProfile = Loader(lazy(() => import("../UserDashboard/User/My Profile/MyProfile")));
const Notification = Loader(lazy(() => import("../UserDashboard/User/Notification")));
const MyPackages = Loader(lazy(() => import("../UserDashboard/User/MyPackages")));
const PaymentHistory = Loader(lazy(() => import("../UserDashboard/Payment/OtherPayment/PaymentHistory")));
const PreviewInvoice = Loader(lazy(() => import("../UserDashboard/Invoice/PreviewInvoice")));
const PropertyPlans = Loader(lazy(() => import("../UserDashboard/Properties/PropertyPlans")));
const PaymentCheckout = Loader(lazy(() => import("../UserDashboard/Invoice/Checkout")));
const ViewSingleProperty = Loader(lazy(() => import("../Component/Properties/ViewSingleProperty")));
const MapDashboard = Loader(lazy(() => import("../UserDashboard/User/Buyer/MapDashboard")));
const PropertyCampare = Loader(lazy(() => import("../UserDashboard/User/Buyer/PropertyCampare")));
const AssignedProperties = Loader(lazy(() => import("../UserDashboard/User/Agent/AssignedProperties")));
const Request = Loader(lazy(() => import("../UserDashboard/User/Seller/AgentRequests")));
const RequestDetails = Loader(lazy(() => import("../UserDashboard/User/Seller/RequestDetails")));
const SellerDetails = Loader(lazy(() => import("../UserDashboard/User/Agent/SellerDetails")));
const HiredAgents = Loader(lazy(() => import("../UserDashboard/User/Agent/HiredAgents")));
const ShortlistedProperty = Loader(lazy(() => import("../UserDashboard/User/Buyer/ShortlistedProperty")));
const OwnerContactPayment = Loader(lazy(() => import("../UserDashboard/Payment/OtherPayment/OwnerContactPayment")));
const SubscriptionPayment = Loader(lazy(() => import("../UserDashboard/Payment/OtherPayment/SubscriptionPayment")));
const LawyerPayment = Loader(lazy(() => import("../UserDashboard/Payment/OtherPayment/LawyerPayment")));
const OwnerDetails = Loader(lazy(() => import("../UserDashboard/Payment/OwnerDetails")));
const BuyerSideDetials = Loader(lazy(() => import("../UserDashboard/User/Lawyer/BuyerSideDetails")));
const LawyerLeadList = Loader(lazy(() => import("../UserDashboard/User/Lawyer/LawyerLeadList")));
const BankerLeadList = Loader(lazy(() => import("../UserDashboard/User/Banker/BankerLeadList")));
const ScheduleMeeting = Loader(lazy(() => import("../UserDashboard/Payment/ScheduleMeeting")));
const InterestedProperties = Loader(lazy(() => import("../UserDashboard/User/Buyer/InterestedProperties")));
const BidProperties = Loader(lazy(() => import("../UserDashboard/User/Buyer/BidProperties")));
const BidingRequest = Loader(lazy(() => import("../UserDashboard/User/Seller/BidingRequest")));
const BookingRequest = Loader(lazy(() => import("../UserDashboard/User/Seller/BookingRequest")));
const Messanger = Loader(lazy(() => import("../Common/Messanger")));
const BookedProperties = Loader(lazy(() => import("../UserDashboard/User/Buyer/BookedProperties/BookedProperties")));
const HireLawyer = Loader(lazy(() => import("../UserDashboard/User/Lawyer/HireLawyer")));
const HireAgent = Loader( lazy(() => import("../UserDashboard/User/Agent/HiredAgents")));
const ContactRequest = Loader(lazy(() => import("../UserDashboard/User/Seller/ContactRequest")));
const BookedPropertyDetails = Loader(lazy(() => import("../UserDashboard/User/Buyer/BookedProperties/index")));
// const DocumentUploadByLawyer = Loader(lazy(() => import('../UserDashboard/User/Lawyer/DocumentUploadByLawyer')));
const DocumentUploadByLawyer = Loader(lazy(() => import("../UserDashboard/User/Lawyer/DocumentUpload")));
// const BuyerDetails = Loader(lazy(() => import('../UserDashboard/User/Banker/BankerLeadDetails')))
const BankerLeadDetails = Loader(lazy(() => import("../UserDashboard/User/Banker/BankerLeadDetails")));
const PropertyRequest = Loader(lazy(() => import("../UserDashboard/User/Seller/PropertyRequest")));
const PropertyAnalysis = Loader(lazy(() => import("../UserDashboard/User/Buyer/PropertyAnalysis")));
const LawyerRequestDetails = Loader(lazy(() => import("../UserDashboard/User/Seller/LawyerRequestDetails")));
const PropertyEvaluators = Loader(lazy(() => import('../UserDashboard/User/Evaluators/HireEvaluators')));
const EvaluatorLeadList =  Loader(lazy(() => import('../UserDashboard/User/Evaluators/EvaluatorLeadList')));
const EvaluatorLeadDetails =  Loader(lazy(() => import('../UserDashboard/User/Evaluators/EvaluatorLeadDetails')));
const EvaluatorPayment =  Loader(lazy(() => import('../UserDashboard/Payment/OtherPayment/EvaluatorPayment')));
 const EvaluatorDetails = Loader(lazy(() => import('../UserDashboard/User/Evaluators/EvaluatorDetails')));
const EditProperty = Loader(lazy(() => import('../UserDashboard/Properties/EditPropertyForm/EditProperty')))
const AgentPayment =Loader(lazy(() => import('../UserDashboard/Payment/OtherPayment/AgentPayment')))
const  AgentLeadList =Loader(lazy(() => import('../UserDashboard/User/Agent/AgentLeadList')))
const  AgentReviews =Loader(lazy(() => import('../UserDashboard/User/Agent/AgentReviews')))
const BookedPropertiesOfAgent = Loader(lazy(() => import('../UserDashboard/User/Agent/BookedPropertiesByAgent')))
const PropertyVisitors = Loader(lazy(() => import('../UserDashboard/User/Agent/Property Visitors/PropertyVisitors')))
const BookedPropertyPayments = Loader(lazy(() => import('../UserDashboard/User/Buyer/BookedProperties/BookedPropertyPayments')))
const PrivacyPolicy = Loader(lazy(() => import('../Common/PrivacyPolicy')))
const TermsOfServices = Loader(lazy(() => import('../Common/TermsOfServices')))
const AllPropertyAnalysis = Loader(lazy(() => import('../Home/PropertyAnalysis/index')))


function Router() {

  let element = useRoutes([
    {
      element: <AuthLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/Login", element: <Login /> },
        // { path: "/User", element: <User /> },
        { path: "/Registration", element: <Registration /> },
        { path: "/ForgetPassword", element: <ForgetPassword /> },
        { path: "/MobilenoVerification", element: <MobilenoVerification /> },
        { path: "/PropertyListHome", element: <PropertyListHome /> },
        { path: "/ViewSingleProperty/:id", element: <ViewSingleProperty /> },
        { path: "/privacy", element: <PrivacyPolicy /> },
        { path: "/TermsOfServices", element: <TermsOfServices /> },
        {path: "/home/propertyAnalysis", element: <AllPropertyAnalysis /> },
        {
          element: <DashboardLayout />,
          children: [
            { path: "/UserDashboard", element: <UserDashboard /> },
            { path: "/SelectPropertyType", element: <SelectPropertyType /> },
            { path: "/SelectPropertyFor", element: <SelectPropertyFor /> },
            { path: "/AddProperty", element: <AddProperty /> },
            { path: "/EditProperty/:id", element: <EditProperty /> },
            { path: "/PropertyMedia", element: <PropertyMedia /> },
            { path: "/OwnerPropertiesScreen", element: <OwnerPropertiesScreen /> },
            { path: "/OwnerBookedPropertyDetails/:id/", element: <OwnerBookedPropertyDetails /> },
            { path: "/ViewPropertyDetails/:id",element: <ViewPropertyDetails />},
            { path: "/ViewPropertyDetails/:id/:agentId/",element: <ViewPropertyDetails />},
            {
              path: "/PropertyVerification/:id",
              element: <PropertyVerification />,
            },
            { path: "/FavoritesProperties", element: <FavoritesProperties /> },
            { path: "/PropertyPlans", element: <PropertyPlans /> },
            { path: "/MyProfile", element: <MyProfile /> },
            { path: "/Notification", element: <Notification /> },
            { path: "/MyPackages", element: <MyPackages /> },
            { path: "/TabPanel", element: <TabPanel /> },
            { path: "/PaymentHistory", element: <PaymentHistory /> },
            { path: "/PreviewInvoice/:id", element: <PreviewInvoice /> },
            { path: "/PaymentCheckout/:packageid", element: <PaymentCheckout />, },
            { path: "/BookedProperties/Payments/:id", element: <BookedPropertyPayments />, },
            { path: "/Messanger", element: <Messanger /> },
            { path: "/MapDashboard", element: <MapDashboard /> },
            { path: "/PropertyCampare", element: <PropertyCampare /> },
            { path: "/AssignedProperties", element: <AssignedProperties /> },
            { path: "/Request/:id", element: <Request /> },
            { path: "/RequestDetails/:agentId/:id",element: <RequestDetails /> },
            { path: "/RequestDetails/:agentId/",element: <RequestDetails /> },
            { path: "/SellerDetails/:id", element: <SellerDetails /> },
            { path: "/HiredAgents/:id", element: <HiredAgents /> },
            { path: "/ShortlistedProperty", element: <ShortlistedProperty /> },
            { path: "/OwnerContactPayment", element: <OwnerContactPayment /> },
            { path: "/subscriptionPayment", element: <SubscriptionPayment /> },
            { path: "/LawyerPayment", element: <LawyerPayment /> },
            { path: "/EvaluatorPayment", element: <EvaluatorPayment /> },
            { path: "/AgentPayment", element: <AgentPayment /> },
            { path: "/OwnerDetails/:id", element: <OwnerDetails /> },
            { path: "/OwnerDetails/:id/:agentId", element: <OwnerDetails /> },
            { path: "/LawyerLeadList", element: <LawyerLeadList /> },
            { path: "/BuyerSideDetials/:id", element: <BuyerSideDetials /> },
            { path: "/ScheduleMeeting", element: <ScheduleMeeting /> },
            { path: "/InterestedProperties", element: <InterestedProperties />,},
            { path: "/BidingRequest", element: <BidingRequest /> },
            { path: "/BookingRequest", element: <BookingRequest /> },
            { path: "/BookedProperties", element: <BookedProperties /> },
            { path: "/BidProperties", element: <BidProperties /> }, 
            { path: "/BookedPropertiesByAgent", element: <BookedPropertiesOfAgent /> },
            { path: "/HireLawyer", element: <HireLawyer /> },
            { path: "/HireAgent", element: <HireAgent /> },
            { path: "/MyProperties/HireAgentForProperty/", element: <HireAgent /> },
            { path: "/ContactRequest", element: <ContactRequest /> },
            { path: "/BookedPropertyDetails/:id/:bookingId",element: <BookedPropertyDetails />},
            { path: "/DocumentUploadByLawyer", element: <DocumentUploadByLawyer />},
            { path: "/BankerLeadList", element: <BankerLeadList /> },
            { path: "/BankerLeadDetails/:id", element: <BankerLeadDetails /> },
            { path: "/PropertyRequest/:id", element: <PropertyRequest /> },
            { path: "/PropertyAnalysis", element: <PropertyAnalysis /> },
            { path: "/LawyerRequestDetails/:id/", element: <LawyerRequestDetails /> },
            { path: "/PropertyEvaluators", element: <PropertyEvaluators /> }, 
            { path: "/EvaluatorLeadList", element: <EvaluatorLeadList /> },
            { path: "/EvaluatorLeadDetails/:id", element: <EvaluatorLeadDetails /> },
            { path: "/EvaluatorDetails/:id", element: <EvaluatorDetails /> },
            { path: "/AgentLeadList/", element: <AgentLeadList /> },
            { path: "/AgentReviews/", element: <AgentReviews /> },
            { path: "/PropertyVisitors/", element: <PropertyVisitors /> },
          ],
        },
      ],
    },
  ]);

  return element;
}

export default Router;
