import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopAdvertiseData } from "../../Redux/Slices/AdvertiseSlice";
import { useNavigate } from "react-router-dom";
import AdContactModal from "./ContactModal";


function HomePageAdvetise() {
     
    const TopadRef = useRef(null); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Properties = useSelector((state) => state.add.TopAddList);
    const UserId = localStorage.getItem("userId");
    console.log("AdvertiseProperties",Properties)
    
    const [showModal, setShowModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [viewedAdId, setViewedAdId] = useState(null); 

    console.log("viewedAdId", viewedAdId);
    
     
      useEffect(() => {
        const formData = new FormData();
      formData.append('adFormat', 'image');
      formData.append('pageToShow', 'homepage');
      formData.append('adPosition', 'top');

      const fetchAdvertiseData = () => {
          dispatch(getTopAdvertiseData({ data: formData }));
          setViewedAdId(null); 
        };
    
        fetchAdvertiseData();
    
        const interval = setInterval(fetchAdvertiseData, 25000);
        return () => clearInterval(interval);
      }, [dispatch]);
    
     
      const redirectToAd = (link,AdId) => {

        window.open(link, "_blank");

        const data = {
          method: "PUT",
        };
        
        fetch(`https://mloneusk.lol/api/form/increaseresult/${AdId}`, data)
          .then((response) => response.json())
          .then((data) => {
            console.log("click on AD", data);
          })
          .catch((err) => console.log(err));
    };
    
      const closeModal = () => {
        setShowModal(false);
        setPhoneNumber("");
      };

    
      // Handle view count API call
      const handleAdView = () => {
        if (Properties?.adId && viewedAdId !== Properties.adId) {
          console.log("Ad is now visible. Calling view count API...");
    
          fetch(`https://mloneusk.lol/api/stat/add?userId=${UserId}&adId=${Properties?.adId}`, {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => console.log("View count API response:", data))
            .catch((err) => console.log("View count API error:", err))
    
          setViewedAdId(Properties.adId); 
        }
      };
    

      // Set up Intersection Observer
      useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
              handleAdView();
            }
          },
          {
            threshold: 0.5,
          }
        );
    
        if (TopadRef.current) {
          observer.observe(TopadRef.current);
        }

        return () => {
          if (TopadRef.current) {
            observer.unobserve(TopadRef.current);
          }
        };
      }, [Properties, viewedAdId]); 


    return (
        <div className="container mt-5 position-relative"  ref={TopadRef}>
        <div className="property-card position-relative">
          {/* Image Section */}
          <div className="image-wrapper">
            <img
              src={Properties?.mediaUrl} // Replace with your actual image URL
              alt="Property"
              className="property-image w-100"
            />
          </div>
  
          {/* Content Section */}
          <div className="content-overlay">
            <h5 className="fw-bold text-white">
              {Properties?.headLine}
            </h5>
            <p className="text-white">
            {Properties?.description?.length > 100
              ? `${Properties.description.slice(0, 130)}...`
              : Properties?.description}
            </p>
            <a onClick={()=>redirectToAd(Properties.siteLink,Properties.adId)} className="explore-button">
              Explore Now →
            </a>
          </div>
        </div>
      </div>
      );
}

export default HomePageAdvetise

