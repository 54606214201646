import React from "react";
import Footer from "./footer";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import MapwithList from "../UserDashboard/MapWithProperty/MapwithList";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomePageAdvetise from "../Home/Advertisement/HomePageAdvertise";
import HomePageAdvertiseMiddle from "../Home/Advertisement/HomePageAdvertiseMiddle";
import HomePageAdvertiseBottom from "../Home/Advertisement/HomePageAdvertiseBottom";
import CapitalGainsTaxCalculator from "./CapitalGainTaxCalculator";
import TabComponent from "./CalculatorTab";

function Home() {


  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const token = localStorage.getItem("token");
  const UserRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate()
  console.log(token, "token")

  
  const checkAuthentication = (url) =>{
        if(token == null ){
          navigate('/Login')
        }else(
          navigate(`/${url}`)
        )
  }



  return (
    <section class="main ">
      <Navbar />

      <div
      
        style={{
          backgroundImage: `url('./images/illustration/home-bg-Image.png')`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
           height:"63vh"
        }}
      >
        
        <section class="live-work-main container-fluid">
      

          {/* <!-- Tab Navigation --> */}
        
            <ul
              class="nav live-work-nav nav-pills justify-content-center"
              id="propertyTabs"
              role="tablist"
              style={{paddingTop:"14%"}}
            >
              <li class="nav-item">
                <a
                  class="nav-link active buy-btn-head"
                  id="buy-tab"
                  data-bs-toggle="pill"
                  href="#buy"
                  role="tab"
                  aria-controls="buy"
                  aria-selected="true"
                >
                  Buy
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link buy-btn-head"
                  id="rent-tab"
                  data-bs-toggle="pill"
                  href="#rent"
                  role="tab"
                  aria-controls="rent"
                  aria-selected="false"
                >
                  Rent
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link buy-btn-head"
                  id="commercial-tab"
                  data-bs-toggle="pill"
                  href="#commercial"
                  role="tab"
                  aria-controls="commercial"
                  aria-selected="false"
                >
                  Commercial
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link buy-btn-head"
                  id="coliving-tab"
                  data-bs-toggle="pill"
                  href="#coliving"
                  role="tab"
                  aria-controls="coliving"
                  aria-selected="false"
                >
                  PG/Co Living
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link buy-btn-head"
                  id="plots-tab"
                  data-bs-toggle="pill"
                  href="#plots"
                  role="tab"
                  aria-controls="plots"
                  aria-selected="false"
                >
                  Plots
                </a>
              </li>
            </ul>

            {/* <!-- Tab Content --> */}

            <div class="tab-content" id="propertyTabsContent">
              <div
                class="tab-pane fade show active"
                id="buy"
                role="tabpanel"
                aria-labelledby="buy-tab"
              >
                {/* <!-- Buy Property Search Form --> */}
                <form>
                  <input
                    class="local-society"
                    type="text"
                    placeholder="Type Locality Or Project/Society or Builder"
                  />
                  <select
                    class="buy-prop-type"
                    id="buy-property-type"
                    name="buy-property-type"
                  >
                    <option value="house">Property Type</option>
                    <option value="house">House</option>
                    <option value="apartment">Apartment</option>
                    <option value="condo">Villa</option>
                  </select>

                  <button class="btn live-search" type="submit">
                    <i class="bi bi-search"></i> Search
                  </button>
                </form>
              </div>
              <div
                class="tab-pane fade"
                id="rent"
                role="tabpanel"
                aria-labelledby="rent-tab"
              >
                {/* <!-- Rent Property Search Form --> */}
                <form>
                  {/* <!-- Add your rent property search form fields here --> */}
                  {/* <!-- <h3>Rent Property Search</h3> --> */}
                  <input
                    class="local-society"
                    type="text"
                    placeholder="Type Locality Or Project/Society or Builder"
                  />
                  <select
                    class="buy-prop-type"
                    id="rent-property-type"
                    name="rent-property-type"
                  >
                    <option value="property">Property Type</option>
                    <option value="house">House</option>
                    <option value="apartment">Apartment</option>
                    <option value="condo">Villa</option>
                  </select>
                  {/* <!-- Add more search fields as needed --> */}
                  <button class="btn live-search" type="submit">
                    <i class="bi bi-search"></i> Search
                  </button>
                </form>
              </div>
              <div
                class="tab-pane fade"
                id="commercial"
                role="tabpanel"
                aria-labelledby="commercial-tab"
              >
                {/* <!-- Commercial Property Search Form --> */}
                <form>
                  {/* <!-- Add your commercial property search form fields here --> */}
                  {/* <!-- <h3>Commercial Property Search</h3> --> */}
                  <input
                    class="local-society"
                    type="text"
                    placeholder="Type Locality Or Project/Society or Builder"
                  />
                  <select
                    class="buy-prop-type"
                    id="commercial-property-type"
                    name="commercial-property-type"
                  >
                    <option value="property">Property Type</option>
                    <option value="office-space">Office Space</option>
                    <option value="retail-space">Retail Space</option>
                    <option value="industrial-space">Industrial Space</option>
                  </select>
                  {/* <!-- Add more search fields as needed --> */}
                  <button class="btn live-search" type="submit">
                    <i class="bi bi-search"></i> Search
                  </button>
                </form>
              </div>
              <div
                class="tab-pane fade"
                id="coliving"
                role="tabpanel"
                aria-labelledby="coliving-tab"
              >
                {/* <!-- Commercial Property Search Form --> */}
                <form>
                  {/* <!-- Add your commercial property search form fields here --> */}
                  {/* <!-- <h3>Commercial Property Search</h3> --> */}
                  <input
                    class="local-society"
                    type="text"
                    placeholder="Type Locality Or Project/Society or Builder"
                  />
                  <select
                    class="buy-prop-type"
                    id="coliving-property-type"
                    name="coliving-property-type"
                  >
                    <option value="property">Property Type</option>
                    <option value="office-space">Office Space</option>
                    <option value="retail-space">Retail Space</option>
                    <option value="industrial-space">Industrial Space</option>
                  </select>
                  {/* <!-- Add more search fields as needed --> */}
                  <button class="btn live-search" type="submit">
                    <i class="bi bi-search"></i> Search
                  </button>
                </form>
              </div>
              <div
                class="tab-pane fade"
                id="plots"
                role="tabpanel"
                aria-labelledby="plots-tab"
              >
                {/* <!-- Commercial Property Search Form --> */}
                <form>
                  {/* <!-- Add your commercial property search form fields here --> */}
                  {/* <!-- <h3>Commercial Property Search</h3> --> */}
                  <input
                    class="local-society"
                    type="text"
                    placeholder="Type Locality Or Project/Society or Builder"
                  />
                  <select
                    class="buy-prop-type"
                    id="plots-property-type"
                    name="plots-property-type"
                  >
                    <option value="property">Property Type</option>
                    <option value="office-space">Office Space</option>
                    <option value="retail-space">Retail Space</option>
                    <option value="industrial-space">Industrial Space</option>
                  </select>
                  {/* <!-- Add more search fields as needed --> */}
                  <button class="btn live-search" type="submit">
                    <i class="bi bi-search"></i> Search
                  </button>
                </form>
              </div>
            </div>
          
        </section>
      </div>
      <section class=" mb-5 container-fluid">
        <div class="row">
          <MapwithList />
        </div>
      </section>
      <HomePageAdvetise/>
      <section class="lifestyle-explore-main container mt-5 mb-5">
        <div class="lifestyle-text mb-4">
          <h5 class="fw-bold mb-5">Latest Properties</h5>
        </div>
        <div class="lifestyle-content row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-md-3 justify-content-between pb-4 mt-4 gy-4">
          <div class="card lifestyle-crd col">
            <div class="card-body lifestyle-crd-bd text-center">
              <img
                class="img-fluid"
                style={{ height: "70px", width: "70px" }}
                src="./images/illustration/apartment2.png"
                alt=""
              />
              <h6 class="card-title lifestyle-crd-heading">Apartment</h6>
              <p class="card-subtitle lifestyle-para mb-2 text-body-secondary">
                102 Properties
              </p>
              <a href="#" class="card-link btn exp-all text-white">
                Explore all <i class="bi bi-caret-right-fill"></i>
              </a>
            </div>
          </div>
          <div class="card lifestyle-crd col">
            <div class="card-body lifestyle-crd-bd text-center">
              <img
                class="img-fluid"
                style={{ height: "70px", width: "70px" }}
                src="./images/illustration/house.png "
                alt=""
              />
              <h6 class="card-title lifestyle-crd-heading">Homes</h6>
              <p class="card-subtitle lifestyle-para mb-2 text-body-secondary">
                102 Properties
              </p>
              <a href="#" class="card-link btn exp-all text-white">
                Explore all <i class="bi bi-caret-right-fill"></i>
              </a>
            </div>
          </div>
          <div class="card lifestyle-crd col">
            <div class="card-body lifestyle-crd-bd text-center">
              <img
                class="img-fluid"
                style={{ height: "70px", width: "70px" }}
                src="./images/illustration/building1.png "
                alt=""
              />
              <h6 class="card-title lifestyle-crd-heading">Commercial</h6>
              <p class="card-subtitle lifestyle-para mb-2 text-body-secondary">
                102 Properties
              </p>

              <a href="#" class="card-link btn exp-all text-white">
                Explore all <i class="bi bi-caret-right-fill"></i>
              </a>
            </div>
          </div>
          <div class="card lifestyle-crd col">
            <div class="card-body lifestyle-crd-bd text-center">
              <img
                class="img-fluid"
                style={{ height: "70px", width: "70px" }}
                src="./images/illustration/beach-house.png "
                alt=""
              />
              <h6 class="card-title lifestyle-crd-heading">Villas/Resorts</h6>
              <p class="card-subtitle lifestyle-para mb-2 text-body-secondary">
                102 Properties
              </p>

              <a href="#" class="card-link btn exp-all text-white">
                Explore all <i class="bi bi-caret-right-fill"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pop-owner-prop container mt-5">
        <div class="pop-owner-prop-text mb-4">
          <h5 class="fw-bold mb-5">Popular Projects</h5>
        </div>
        <section class="pop-owner-prop-card  mt-4 ">
           <Slider {...settings}>
            <div
              class="card pop-owner-prop-crd me-5"
              style={{ maxWidth: "400px", maxHeight: "400px" }}
            >
              <a href="#">
                {" "}
                <img
                  class="pop-owner-img"
                  src="images/illustration/apartment-interior-2.avif"
                  alt=""
                />
              </a>
              <div class="card-body pop-owner-prop-body">
                <div class="d-flex justify-content-between">
                  <div class="col-7">
                    <h5 class="pop-owner-prop-ttl card-title">
                      <i class="bi bi-currency-rupee"></i>45 Lac{" "}
                      <span class="bhk">/1BHK Cross Road, Pune</span>
                    </h5>
                  </div>
                  <div>
                    <a
                      href="dashboard/single-property.html"
                      class="btn view-btn-pop"
                    >
                      View Details
                    </a>
                  </div>
                </div>
                <div class="pop-detail-info d-flex justify-content-between">
                  <span>
                    <i class="fa-solid fa-bed"></i> 3 Beds
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-shower"></i> 2 Bath
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                  </span>
                </div>
              </div>
            </div>

            <div
              class=" items pop-owner-prop-crd me-5"
              style={{ maxWidth: "400px", maxHeight: "200px" }}
            >
              <div class="card">
                <a href="#">
                  {" "}
                  <img
                    class="pop-owner-img"
                    src="images/illustration/apartment-interior1.avif"
                    alt=""
                  />
                </a>
                <div class="card-body pop-owner-prop-body">
                  <div class="d-flex justify-content-between">
                    <div class="col-7">
                      <h5 class="pop-owner-prop-ttl card-title">
                        <i class="bi bi-currency-rupee"></i>45 Lac{" "}
                        <span class="bhk">/1BHK Cross Road, Pune</span>
                      </h5>
                    </div>
                    <div>
                      <a
                        href="dashboard/single-property.html"
                        class="btn view-btn-pop"
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                  <div class="pop-detail-info d-flex justify-content-between">
                    <span>
                      <i class="fa-solid fa-bed"></i> 3 Beds
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-shower"></i> 2 Bath
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class=" items">
              <div
                class="card pop-owner-prop-crd me-5"
                style={{ maxWidth: "400px", maxHeight: "200px" }}
              >
                <a href="#">
                  <img
                    class="pop-owner-img"
                    src="images/illustration/row-house-1.avif"
                    alt=""
                  />
                </a>
                <div class="card-body pop-owner-prop-body">
                  <div class="d-flex justify-content-between">
                    <div class="col-7">
                      <h5 class="pop-owner-prop-ttl card-title">
                        <i class="bi bi-currency-rupee"></i>45 Lac{" "}
                        <span class="bhk">/1BHK Cross Road, Pune</span>
                      </h5>
                    </div>
                    <div>
                      <a
                        href="dashboard/single-property.html"
                        class="btn view-btn-pop"
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                  <div class="pop-detail-info d-flex justify-content-between">
                    <span>
                      <i class="fa-solid fa-bed"></i> 3 Beds
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-shower"></i> 2 Bath
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class=" items">
              <div
                class="card pop-owner-prop-crd me-5"
                style={{ maxWidth: "400px", maxHeight: "200px" }}
              >
                <a href="#">
                  <img
                    class="pop-owner-img"
                    src="images/illustration/row-house-1.avif"
                    alt=""
                  />
                </a>
                <div class="card-body pop-owner-prop-body">
                  <div class="d-flex justify-content-between">
                    <div class="col-7">
                      <h5 class="pop-owner-prop-ttl card-title">
                        <i class="bi bi-currency-rupee"></i>45 Lac{" "}
                        <span class="bhk">/1BHK Cross Road, Pune</span>
                      </h5>
                    </div>
                    <div>
                      <a
                        href="dashboard/single-property.html"
                        class="btn view-btn-pop"
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                  <div class="pop-detail-info d-flex justify-content-between">
                    <span>
                      <i class="fa-solid fa-bed"></i> 3 Beds
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-shower"></i> 2 Bath
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class=" items">
              <div
                class="card pop-owner-prop-crd me-5"
                style={{ maxWidth: "400px", maxHeight: "200px" }}
              >
                <a href="#">
                  {" "}
                  <img
                    class="pop-owner-img"
                    src="images/illustration/apartment-interior-2.avif"
                    alt=""
                  />
                </a>
                <div class="card-body pop-owner-prop-body">
                  <div class="d-flex justify-content-between">
                    <div class="col-7">
                      <h5 class="pop-owner-prop-ttl card-title">
                        <i class="bi bi-currency-rupee"></i>45 Lac{" "}
                        <span class="bhk">/1BHK Cross Road, Pune</span>
                      </h5>
                    </div>
                    <div>
                      <a
                        href="dashboard/single-property.html"
                        class="btn view-btn-pop"
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                  <div class="pop-detail-info d-flex justify-content-between">
                    <span>
                      <i class="fa-solid fa-bed"></i> 3 Beds
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-shower"></i> 2 Bath
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class=" items">
              <div
                class="card pop-owner-prop-crd me-5"
                style={{ maxWidth: "400px", maxHeight: "200px" }}
              >
                <a href="#">
                  {" "}
                  <img
                    class="pop-owner-img"
                    src="images/illustration/apartment-interior-2.avif"
                    alt=""
                  />
                </a>
                <div class="card-body pop-owner-prop-body">
                  <div class="d-flex justify-content-between">
                    <div class="col-7">
                      <h5 class="pop-owner-prop-ttl card-title">
                        <i class="bi bi-currency-rupee"></i>45 Lac{" "}
                        <span class="bhk">/1BHK Cross Road, Pune</span>
                      </h5>
                    </div>
                    <div>
                      <a
                        href="dashboard/single-property.html"
                        class="btn view-btn-pop"
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                  <div class="pop-detail-info d-flex justify-content-between">
                    <span>
                      <i class="fa-solid fa-bed"></i> 3 Beds
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-shower"></i> 2 Bath
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class=" items">
              <div
                class="card pop-owner-prop-crd me-5"
                style={{ maxWidth: "400px", maxHeight: "200px" }}
              >
                <a href="#">
                  {" "}
                  <img
                    class="pop-owner-img"
                    src="images/illustration/apartment-interior1.avif"
                    alt=""
                  />
                </a>
                <div class="card-body pop-owner-prop-body">
                  <div class="d-flex justify-content-between">
                    <div class="col-7">
                      <h5 class="pop-owner-prop-ttl card-title">
                        <i class="bi bi-currency-rupee"></i>45 Lac{" "}
                        <span class="bhk">/1BHK Cross Road, Pune</span>
                      </h5>
                    </div>
                    <div>
                      <a
                        href="dashboard/single-property.html"
                        class="btn view-btn-pop"
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                  <div class="pop-detail-info d-flex justify-content-between">
                    <span>
                      <i class="fa-solid fa-bed"></i> 3 Beds
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-shower"></i> 2 Bath
                    </span>{" "}
                    <span>
                      <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </Slider>
        
        </section>
      </section>
      <section class="insights-tool-main">
  <div class="container">
    <div class="insight-text pt-5">
      <h5 class="fw-bold mb-5">Insights & Tools</h5>
    </div>
    <div class="row">
      <div class="col-md-6 d-flex justify-content-center mb-4 mb-md-0">
        <img
          src="images/illustration/BITools_RealEstateIndustry.png"
          alt=""
          // style={{maxWidth: "100%", height: "auto"}}
        />
      </div>

      
      <div class="col-md-6 d-flex justify-content-center">
        <img
          src="images/illustration/AITools_realestateindustry.png"
          alt=""
          // style={{maxWidth: "100%", height: "auto"}}
        />
      </div>
    </div>
  </div>
</section>
   <HomePageAdvertiseMiddle/>
      <section class="Featured-property-main container mb-5 pb-4">
        <div>
          <h5 class="fw-bold mb-5 mt-5">Featured Property For Sell</h5>
        </div>
        <div class="featured-content row row-cols-1 row-cols-md-2  row-cols-xl-2 g-3">
          <div class="col  pop-owner-prop-crd ">
            <div class="card-body Featured-body d-flex ">
              <div class="featured-img">
                {" "}
                <a href="#" class="position-relative">
                  {" "}
                  <span class="Sell-badge badge text-bg-danger">Sell</span>{" "}
                  <img src="images/illustration/row-house-1.avif" alt="" />
                </a>
              </div>
              <div class="featured-right">
                <h5 class="Featured-ttl card-title">
                  <i class="bi bi-currency-rupee"></i>1.5 cr{" "}
                  <span class="bhk">/5BHK Cross Road, Pune</span>
                </h5>
                <div class="Featured-info  mt-2 mb-2">
                  <span>
                    <i class="fa-solid fa-bed"></i> 3 Beds
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-shower"></i> 2 Bath
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                  </span>
                </div>
                <Link to="/ViewPropertyDetails" class="btn view-btn-pop">
                  View Details
                </Link>
              </div>
            </div>
          </div>
          <div class="col  pop-owner-prop-crd">
            <div class="card-body Featured-body d-flex ">
              <div class="featured-img">
                {" "}
                <a href="#" class="position-relative">
                  {" "}
                  <span class="Sell-badge badge text-bg-danger">Sell</span>{" "}
                  <img src="images/illustration/row-house-1.avif" alt="" />
                </a> 
              </div>
              <div class="featured-right">
                <h5 class="Featured-ttl card-title">
                  <i class="bi bi-currency-rupee"></i>1.5 cr{" "}
                  <span class="bhk">/5BHK Cross Road, Pune</span>
                </h5>
                <div class="Featured-info  mt-2 mb-2">
                  <span>
                    <i class="fa-solid fa-bed"></i> 3 Beds
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-shower"></i> 2 Bath
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                  </span>
                </div>
                <a href="#" class="btn view-btn-pop">
                  View Details
                </a>
              </div>
            </div>
          </div>
          <div class="col  pop-owner-prop-crd">
            <div class="card-body Featured-body d-flex ">
              <div class="featured-img">
                {" "}
                <a href="#" class="position-relative">
                  {" "}
                  <span class="Sell-badge badge text-bg-danger">Sell</span>{" "}
                  <img src="images/illustration/row-house-1.avif" alt="" />
                </a>
              </div>
              <div class="featured-right">
                <h5 class="Featured-ttl card-title">
                  <i class="bi bi-currency-rupee"></i>1.5 cr{" "}
                  <span class="bhk">/5BHK Cross Road, Pune</span>
                </h5>
                <div class="Featured-info  mt-2 mb-2">
                  <span>
                    <i class="fa-solid fa-bed"></i> 3 Beds
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-shower"></i> 2 Bath
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                  </span>
                </div>
                <a href="#" class="btn view-btn-pop">
                  View Details
                </a>
              </div>
            </div>
          </div>
          <div class="col  pop-owner-prop-crd">
            <div class="card-body Featured-body d-flex ">
              <div class="featured-img">
                {" "}
                <a href="#" class="position-relative">
                  {" "}
                  <span class="Sell-badge badge text-bg-danger">Sell</span>{" "}
                  <img src="images/illustration/row-house-1.avif" alt="" />
                </a>
              </div>
              <div class="featured-right">
                <h5 class="Featured-ttl card-title">
                  <i class="bi bi-currency-rupee"></i>1.5 cr{" "}
                  <span class="bhk">/5BHK Cross Road, Pune</span>
                </h5>
                <div class="Featured-info  mt-2 mb-2">
                  <span>
                    <i class="fa-solid fa-bed"></i> 3 Beds
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-shower"></i> 2 Bath
                  </span>{" "}
                  <span>
                    <i class="fa-solid fa-puzzle-piece"></i> 700 Sq.Ft.
                  </span>
                </div>
                <a href="#" class="btn view-btn-pop">
                  View Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="vendor-main container">
      <div>
          <h5 class="fw-bold mb-5 mt-5">Hire Vendor</h5>
        </div>
        
      <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
        <div class=" mb-6">
          <div class="card shadow-hover-xs-4 agent-3">
          <div class="card-header text-center bg-transparent text-center p-0 pt-3">
          <a href="agent-details-1.html" class="d-inline-block mb-2 w-120px h-120">
          <img src="images/agent-1.jpg" alt="Max Kordek" />
          </a>
          <a href="agent-details-1.html" class="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 hover-primary ">Agent</a>

          <ul class="list-inline mb-0">
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-twitter"></i></a>
          </li>
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-facebook-f"></i></a>
          </li>
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary">
          <i class="fab fa-skype"></i></a>
          </li>
          <li class="list-inline-item">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-linkedin-in"></i></a>
          </li>
          </ul>
          <button onClick={()=>checkAuthentication('HireAgent')} type="button" class="btn btn-lg w-100 text-white bg-hover-primary"> Hire Me </button>
          </div>

          </div>
        </div>
        <div class=" mb-6">
          <div class="card shadow-hover-xs-4 agent-3">
          <div class="card-header text-center bg-transparent text-center p-0 pt-3">
          <a href="agent-details-1.html" class="d-inline-block mb-2 w-120px h-120">
          <img src="images/agent-1.jpg" alt="Max Kordek" />
          </a>
          <a href="agent-details-1.html" class="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 hover-primary">Lawyer</a>

          <ul class="list-inline mb-0">
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-twitter"></i></a>
          </li>
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-facebook-f"></i></a>
          </li>
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary">
          <i class="fab fa-skype"></i></a>
          </li>
          <li class="list-inline-item">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-linkedin-in"></i></a>
          </li>
          </ul>
          <button  onClick={()=>checkAuthentication('HireLawyer')} type="button" class="btn btn-lg w-100 text-white bg-hover-primary"> Hire Me </button>
          </div>

          </div>
        </div>
        <div class=" mb-6">
          <div class="card shadow-hover-xs-4 agent-3">
          <div class="card-header text-center bg-transparent text-center p-0 pt-3">
          <a href="agent-details-1.html" class="d-inline-block mb-2 w-120px h-120">
          <img src="images/agent-1.jpg" alt="Max Kordek" />
          </a>
          <a href="agent-details-1.html" class="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 hover-primary">Property Evaluators</a>

          <ul class="list-inline mb-0">
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-twitter"></i></a>
          </li>
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-facebook-f"></i></a>
          </li>
          <li class="list-inline-item mr-6">
          <a href="#" class="text-muted hover-primary">
          <i class="fab fa-skype"></i></a>
          </li>
          <li class="list-inline-item">
          <a href="#" class="text-muted hover-primary"><i class="fab fa-linkedin-in"></i></a>
          </li>
          </ul>
          <button onClick={()=>checkAuthentication('PropertyEvaluators')} type="button" class="btn btn-lg w-100 text-white bg-hover-primary"> Hire Me</button>
          </div>
          </div>
        </div>
      </div>
      </section>
      <section class="lifestyle-explore-main container mt-5 mb-5">
        <div class="guide-text mb-4  ">
          <h5 class="fw-bold">Your Real Estate Guide</h5>
        </div>

        <div class="lifestyle-content row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-md-3 justify-content-between pb-4 mt-4 gy-4">
          <div class="card lifestyle-crd col">
            <div class="card-body lifestyle-crd-bd ">
            <div className="guide-img">
              <img
                class="h-100 w-100"
                src="./images/illustration/construction.png "
                alt=""
              />
              </div>
              <h6 class="card-title lifestyle-crd-heading">
                Home Buying Guide
              </h6>
              <p class="card-subtitle lifestyle-para mb-2 text-body-secondary">
                Check property rates and prices
              </p>

              {/* <!-- <a href="#" class="card-link btn exp-all">Explore all <i class="bi bi-caret-right-fill"></i></a> --> */}
            </div>
          </div>
          <div class="card lifestyle-crd col">
            <div class="card-body lifestyle-crd-bd ">
              <div className="guide-img">
              <img
                class="h-100 w-100"
                src="./images/illustration/interior-design.png "
                alt=""
              />
              </div>
             
              <h6 class="card-title lifestyle-crd-heading">Home Design</h6>
              <p class="card-subtitle lifestyle-para mb-2 text-body-secondary">
                Check property rates and prices
              </p>

              {/* <!-- <a href="#" class="card-link btn exp-all">Explore all <i class="bi bi-caret-right-fill"></i></a> --> */}
            </div>
          </div>
          <div class="card lifestyle-crd col">
            <div class="card-body lifestyle-crd-bd ">
            <div className="guide-img">
              <img
                class="h-100 w-100"
                src="./images/illustration/signing.png"
                alt=""
              />
              </div>
              <h6 class="card-title lifestyle-crd-heading"> Loan/Documents</h6>
              <p class="card-subtitle lifestyle-para mb-2 text-body-secondary">
                Check property rates and prices
              </p>

              {/* <!-- <a href="#" class="card-link btn exp-all">Explore all <i class="bi bi-caret-right-fill"></i></a> --> */}
            </div>
          </div>
          <div class="card lifestyle-crd col">
            <div class="card-body lifestyle-crd-bd ">
            <div className="guide-img">
              <img
                class="h-100 w-100"
                src="./images/illustration/analysis.png "
                alt=""
              />
              </div>
              <h6 class="card-title lifestyle-crd-heading">
                Property Valuation
              </h6>
              <p class="card-subtitle lifestyle-para mb-2 text-body-secondary">
                Check property rates and prices
              </p>

              {/* <!-- <a href="#" class="card-link btn exp-all">Explore all <i class="bi bi-caret-right-fill"></i></a> --> */}
            </div>
          </div>
        </div>
      </section>
      <TabComponent/>
      <section class="main-people-living">
        <section class="people-living mb-5 ">
          <div class="container">
            <div class="people-living-text pt-5 d-sm-flex justify-content-between mb-4">
              <div>
                <h5>People Love Living Us</h5>
                <p>People who love us & he says good reviews</p>
              </div>
              <div>
                <a href="#">
                  View all testimonials <i class="bi bi-caret-right-fill"></i>
                </a>
              </div>
            </div>
            <div class="people-living-content row row-cols-lg-3 justify-content-between">
              <div class="card col">
                <div class="card-body">
                  <div class="d-flex">
                    <div>
                      <img
                        class="testimonial-img border rounded-circle"
                        src="images/illustration/testimonial.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <h6>Rupesh Sharma</h6>
                      <p>
                        <span>Pune</span>
                      </p>
                    </div>
                  </div>
                  <p class="card-text-testimonial ">
                    He explained that his job is more service than Sells. He
                    proved that time and again with his knowledge of Home
                    construction and the local market.
                  </p>
                </div>
              </div>
              <div class="card col d-none d-md-block">
                <div class="card-body">
                  <div class="d-flex">
                    <div>
                      <img
                        class="testimonial-img border rounded-circle"
                        src="images/illustration/testimonial.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <h6>Rupesh Sharma</h6>
                      <p>
                        <span>Pune</span>
                      </p>
                    </div>
                  </div>
                  <p class="card-text-testimonial ">
                    He explained that his job is more service than Sells. He
                    proved that time and again with his knowledge of Home
                    construction and the local market.
                  </p>
                </div>
              </div>
              <div class="card col d-none d-lg-block">
                <div class="card-body">
                  <div class="d-flex">
                    <div>
                      <img
                        class="testimonial-img border rounded-circle"
                        src="images/illustration/testimonial.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <h6>Rupesh Sharma</h6>
                      <p>
                        <span>Pune</span>
                      </p>
                    </div>
                  </div>
                  <p class="card-text-testimonial ">
                    He explained that his job is more service than Sells. He
                    proved that time and again with his knowledge of Home
                    construction and the local market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <HomePageAdvertiseBottom />
    
      <section class="download-app container">
        <div class="d-md-flex justify-content-between">
          <div class="left-download col-md-7">
            <h5>
              Download <span>Property Nexus</span> App For Android And Iphone
            </h5>
            <p>
              With our app, spend less time searching and more time at your
              dream Home. Download now!
            </p>
            <div class="scan-main d-lg-flex">
              {/* <!-- <div class="d-flex d-sm-none">
            <img class="google-play-img" src="images/illustration/google-play.png" alt="">
            <img class="app-play-img" src="images/illustration/app-store.png" alt="">
          </div> --> */}
              <img
                class="google-play-img"
                src="images/illustration/google-play.png"
                alt=""
              />
              <img
                class="app-play-img"
                src="images/illustration/app-store.png"
                alt=""
              />
              <span>Open Camera & Scan the QR code to Download the App</span>
              <img
                class="scan-img"
                src="images/illustration/code-scanner.png"
                alt=""
              />
            </div>
          </div>
          <div class="right-download">
            <img
              src="images/illustration/mob-img3-removebg-preview (1).png"
              alt=""
            />
          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
}

export default Home;
