import React, { useEffect, useState } from "react";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";
import * as AiIcons from "react-icons/ai";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const navigate = useNavigate();

  const values = {
    email: "",
    password: "",
  };


  const [Login, setLogin] = useState(values);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeLoginInput = (e) => {
    const { name, value } = e.target;

    if(name == "email"){

      const formattedEmail = value.trim().toLowerCase();
      setLogin({
        ...Login,
        [name]: formattedEmail,
      });
    }else{
      setLogin({
        ...Login,
        [name]: value,
      });
    }
   
  };


  const getUserLogin = () => {
    const inputData = {
      method: "POST",
      body: JSON.stringify({
        email: Login.email,
        password: Login.password,
      }),
      headers: {
        "Content-type": "application/json",
      },
    };

    //  dispatch(userRegistration(inputData))

    fetch("https://propertysearch.ai/api/auths/login", inputData)
      .then((response) => response.json())
      .then((data) => {
        console.log("Logined data ", data);
        const user = data.user;
        const token = data.token;

        if (data.status == true) {
          navigate("/UserDashboard");
          setLogin({});
          localStorage.setItem("firstName", user.firstname);
          localStorage.setItem("lastName", user.lastname);
          localStorage.setItem("userRole", user.userrole);
          localStorage.setItem("userId", user.id);
          localStorage.setItem("token", token);
          localStorage.setItem("address", user.city);
          sessionStorage.setItem("email", user.email);
        }else if(data.status == false){
          toast.error(data.message);
        }

        if (user.userrole == "agent") {
          localStorage.setItem("latitude", user.latitude);
          localStorage.setItem("longitude", user.longitude);
        }
      
      })
      .catch((err) => console.log(err));
  };


  const validate = (Login) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!Login.email) {
      errors.email = "Email Cannot be blank";
    } else if (!regex.test(Login.email)) {
      errors.email = "Invalid email format";
    } else if (!Login.password) {
      errors.password = "Password cannot be blank";
    } else if (Login.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(Login));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length == 0 && isSubmitting) {
      getUserLogin();
    }
  }, [formErrors]);


  const token = localStorage.getItem("token");
  if (token) {
    return <Navigate to="/userDashboard" />;
  }
  
  return (
    <>
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <div class="navbar-brand left-navigation col-md-2">
            <Link to="/" class="navbar-brand">
              <img
                src="../images/Property_search_logo_transparent.png"
                alt="Property Nexus"
                width={200}
              />
            </Link>
          </div> 
        </div>
      </nav>
      <div class="container d-flex justify-content-center mb-5">
        <ToastContainer />
       <div class="col-md-7 col-lg-5 col-sm-12 login-container text-center">
            <Link to="/" class="navbar-brand  " style={{backgroundColor:'#1c4d6b', width:'100%'}}>
              <img
                src="../images/Property_search_logo_transparent.png"
                alt="Property Nexus" 
                width={200}
                height={120}
              />
            </Link>
          <form class="login-form ">
            <h3 class="fw-bold gray">Login</h3>
            <div class="form-group mb-4">
              <label for="username" class="sr-only">
                Username
              </label>
              <div class="input-group input-group-lg">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text bg-gray-01 border-1 fs-20"
                    id="inputGroup-sizing-lg"
                  >
                    <BsIcons.BsPerson />
                  </span>
                </div>
                <input
                  type="email"
                  class="form-control border-1  shadow-none fs-14"
                  id="mobile"
                  name="email"
                  value={Login.email}
                  onChange={handleChangeLoginInput}
                  placeholder="Email"
                />
              </div>
              <p style={{ color: "red", fontSize: "12px" }}>
                {formErrors.email}
              </p>
            </div>
            <div class="form-group mb-4">
              <label for="password" class="sr-only">
                Password
              </label>
              <div class="input-group input-group-lg">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-gray-01 border-1 fs-20">
                    <BiIcons.BiLockAlt />
                  </span>
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  class="form-control border-1 shadow-none fs-14"
                  id="password"
                  name="password"
                  value={Login.password}
                  onChange={handleChangeLoginInput}
                  placeholder="Password"
                />
                <div
                  onClick={() => handleTogglePassword()}
                  class="input-group-append"
                >
                  <span class="input-group-text bg-gray-01 border-1 text-body fs-20">
                    {showPassword ? (
                      <AiIcons.AiOutlineEye />
                    ) : (
                      <AiIcons.AiOutlineEyeInvisible />
                    )}
                  </span>
                </div>
              </div>
              <p style={{ color: "red", fontSize: "12px" }}>
                {formErrors.password}
              </p>
            </div>
            <div class="d-flex mb-4">
              {/* <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="remember-me"
                  name="remember-me"
                />
                <label class="form-check-label" for="remember-me">
                  Remember me
                </label>
              </div> */}
              <Link
                to="/ForgetPassword"
                class="d-inline-block ml-auto text-orange fs-15"
              >
                Forgot Password
              </Link>
            </div>
            <button
              onClick={(e) => handleSubmit(e)}
              type="submit"
              style={{ backgroundColor: "#e1777d", color: "white" }}
              class="btn btn-lg btn-block"
            >
              Login
            </button>

            {/* <div class="divider text-center my-2">
              <span class="px-4  lh-17 ">or continue with</span>
            </div>
            <div class="row no-gutters mx-n2">
              <div class="col-6 px-2 mb-4">
                <button class="btn-light btn-lg text-center  btn-block google px-0">
                  <img src="images/google.png" alt="Google" />
                </button>
              </div>
              <div class="col-6 px-2 mb-4 ">
                <button
                  href="#"
                  class="btn-light btn-lg btn-block twitter text-center text-white px-0"
                >
                  <BiIcons.BiLogoLinkedinSquare
                    style={{ fontSize: "1.8rem", color: "#0a66c2" }}
                  />
                </button>
              </div>
            </div> */}
            <div class=" pt-5 pb-5 text-center text-muted">
              Don't have an account.{" "}
              <Link
                to="/Registration"
                class="text-decoration-none fw-bold"
                style={{ color: "#e1777d" }}
              >
                Sign up
              </Link>
            </div>
          </form>
        </div>
      </div>
      <footer class=" mt-15">
        <hr class="hr" />
        <section class="down-foot container d-flex justify-content-between">
          <div class="social-media">
            <span>Follow us on Social Media </span>
            <a href="#">
              <i class="bi bi-facebook"></i>
            </a>
            <a href="#">
              <i class="fa-brands fa-twitter"></i>
            </a>{" "}
            <a href="#">
              <i class="fa-brands fa-linkedin-in"></i>
            </a>{" "}
            <a href="#">
              <i class="fa-brands fa-youtube"></i>
            </a>
          </div>
          <div class="copy">
            <p>©2023 Property Nexus- All Rights Reserved</p>
          </div>
        </section>
      </footer>
    </>
  );
}

export default Login;
