import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


// localBaseUrl = https://mloneusk.lol/
//ServerBAseUrl = https://mloneusk.lol/


export const getTopAdvertiseData = createAsyncThunk(
  "getTopAdvertiseData",
  async ({ data }) => {
    console.log("get Advertise Data", data);
    const response = await axios.get(
      `https://mloneusk.lol/api/ads/searchSingleForTopHome`,
      data
    );
    console.log("get TopAdvertise Data", response.data);
    return response.data;
  }
);


export const getCenterAdvertiseData = createAsyncThunk(
  "getCenterAdvertiseData",
  async ({ data }) => {
    console.log("get Advertise Data", data);
    const response = await axios.get(`https://mloneusk.lol/api/ads/searchSingleForCenterHomeVideo`, data);
    console.log("get CenterAdvertiseData", response.data);
    return response.data;
  }
);

export const getCenterAdvertiseGIFData = createAsyncThunk(
  "getCenterAdvertiseGIFData",
  async ({ data }) => {
    console.log("get Advertise Data", data);
    const response = await axios.get(
      `https://mloneusk.lol/api/ads/searchSingleForCenterHomeGif`,
      data
    );
    console.log("get CenterAdvertiseData", response.data);
    return response.data;
  }
);


export const getBottomAdvertiseData = createAsyncThunk(
  "getBottomAdvertiseData",
  async ({ data }) => {
    console.log("get Advertise Data", data);
    const response = await axios.get(
      `https://mloneusk.lol/api/ads/searchSingleForCenterHomeVideo`,
      data
    );
    console.log("get BottomAdvertiseData", response.data);
    return response.data;
  }
);


export const getDashboardTopAdvertiseData = createAsyncThunk(
  "getDashboardTopAdvertiseData",
  async ({ data }) => {
    console.log("get Advertise Data", data);
    const response = await axios.get(
      `https://mloneusk.lol/api/ads/searchSingleForDashBoardTopImage`,
      data
    );
    console.log("get DashboardTopAdvertiseData", response.data);
    return response.data;
  }
);


export const getDashboardBottomAdvertiseData = createAsyncThunk(
  "getDashboardBottomAdvertiseData",
  async ({ data }) => {
    console.log("get Advertise Data", data);
    const response = await axios.get(
      `https://mloneusk.lol/api/ads/searchSingleForDashBoardBottomImage`, data
    );
    console.log("get DashboardBottomAdvertiseData", response.data);
    return response.data;
  }
);


export const getPropertyAnalysisAdvertiseData = createAsyncThunk(
  "getPropertyAnalysisAdvertiseData",
  async ({ data }) => {
    console.log("get Advertise Data", data);
    const response = await axios.get(
      `https://mloneusk.lol/api/ads/searchSingleForAnalysisTopImage`,
      data
    );
    console.log("get PropertyAnalysisAdvertiseData", response.data);
    return response.data;
  }
);


export const getShortlistedPageAdvertiseData = createAsyncThunk(
  "getShortlistedPageAdvertiseData",
  async ({ data }) => {
    console.log("get Advertise Data", data);
    const response = await axios.get(
      `https://mloneusk.lol/api/ads/searchSingleForShortListTopImage`,
      data
    );
    console.log("get ShortlistedPage AdvertiseData", response.data);
    return response.data;
  }
);




const AdvertismentSlice = createSlice({

  name: "Advertisment",

  initialState: {
    TopAddList: {},
    CenterAddList: {},
    CenterGIFAddList: {},
    BottomAddList: {},
    dashboardTopAddList: {},
    dashboardBottomAddList: {},
    propertyAnalysisAddList: {},
    shortlistedPageAddList: {},
    status: "idle",
    error: ""
  },


  extraReducers(builder) {

    //get Top AdvertiseData

    builder.addCase(getTopAdvertiseData.pending, (state, action) => {
      state.status = "loading"
    })
      .addCase(getTopAdvertiseData.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.TopAddList = action.payload
      })
      .addCase(getTopAdvertiseData.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

    //get center video AdvertiseData 

    builder.addCase(getCenterAdvertiseData.pending, (state, action) => {
      state.status = "loading"
    })
      .addCase(getCenterAdvertiseData.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.CenterAddList = action.payload
      })
      .addCase(getCenterAdvertiseData.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

    //get center GIF AdvertiseData

    builder.addCase(getCenterAdvertiseGIFData.pending, (state, action) => {
      state.status = "loading"
    })
      .addCase(getCenterAdvertiseGIFData.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.CenterGIFAddList = action.payload
      })
      .addCase(getCenterAdvertiseGIFData.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })


    //get Bottom AdvertiseData

    builder.addCase(getBottomAdvertiseData.pending, (state, action) => {
      state.status = "loading"
    })
      .addCase(getBottomAdvertiseData.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.BottomAddList = action.payload
      })
      .addCase(getBottomAdvertiseData.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

    //get Dashboard Top AdvertiseData

    builder.addCase(getDashboardTopAdvertiseData.pending, (state, action) => {
      state.status = "loading"
    })
      .addCase(getDashboardTopAdvertiseData.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.dashboardTopAddList = action.payload
      })
      .addCase(getDashboardTopAdvertiseData.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })


    //get Dashboard Bottom AdvertiseData


    builder.addCase(getDashboardBottomAdvertiseData.pending, (state, action) => {
      state.status = "loading"
    })
      .addCase(getDashboardBottomAdvertiseData.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.dashboardBottomAddList = action.payload
      })
      .addCase(getDashboardBottomAdvertiseData.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })


    //get Property Analysis AdvertiseData


    builder.addCase(getPropertyAnalysisAdvertiseData.pending, (state, action) => {
      state.status = "loading"
    })
      .addCase(getPropertyAnalysisAdvertiseData.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.propertyAnalysisAddList = action.payload
      })
      .addCase(getPropertyAnalysisAdvertiseData.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

    //get Shortlisted page AdvertiseData


    builder.addCase(getShortlistedPageAdvertiseData.pending, (state, action) => {
      state.status = "loading"
    })
      .addCase(getShortlistedPageAdvertiseData.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.shortlistedPageAddList = action.payload
      })
      .addCase(getShortlistedPageAdvertiseData.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

  }


});

export default AdvertismentSlice.reducer;
