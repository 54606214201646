import React from 'react'
import { FaPhone } from "react-icons/fa6";

function AdContactModal({closeModal,MobileNumber}) {



  return (
    <div
    class="modal"
    style={{
      display: "block",
      paddingRight: "17px",
      height: "100%",
      marginTop: "100px",
    }}
    tabindex="2"
    role="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Contact to owner</h5>
          <button
            onClick={closeModal}
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-flex gap-3">
           <span>
           <FaPhone />
            </span> 
          <p>
             {MobileNumber}
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={closeModal}
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AdContactModal