import "./Assets/css/themes.css";
import "./Assets/css/docs.css";
import "./Assets/style.css";
// import "bootstrap/dist/css/bootstrap.css";
import { useGeolocated } from "react-geolocated";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Router from "./Router/Routing";
import { useState } from "react";
import { useEffect } from "react";
import VarificationModal from "./Common/VarificationModal";
import { useSelector, useDispatch } from "react-redux";
import { subscriptionRequestToggle } from "./Redux/Slices/ProfileSlice";


function App() {

  const dispatch = useDispatch();
  const [userLocation, setUserLocation] = useState(null);

  const VerificationStatus = useSelector((state) => state.userProfile.verificationStatus.verificationstatus);
  const subscriptionRequestModal = useSelector((state) => state.userProfile.subscriptionRequestModal);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  // const apiKey = "AIzaSyBQ8OY_ywbcak88FtstwOW61O1hUJ8xGCk";

useEffect(() => {
  const saveLocationToSessionStorage = async (latitude, longitude) => {
    try {
      const apiKey = "AIzaSyBQ8OY_ywbcak88FtstwOW61O1hUJ8xGCk"; // Replace with your API key
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        // Extract city name from response
        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find((component) =>
          component.types.includes("locality")
        );
        const cityName = cityComponent ? cityComponent.long_name : "Unknown City";

        // Save latitude, longitude, and city to session storage
        const userLocation = {
          latitude,
          longitude,
          city: cityName,
        };
        sessionStorage.setItem("userLocation", JSON.stringify(userLocation));
        console.log("Location saved:", userLocation);
      } else {
        console.error("No results found in Geocoding API response.");
      }
    } catch (error) {
      console.error("Error fetching city name:", error);
    }
  };

  if (coords) {
    const { latitude, longitude } = coords;
    saveLocationToSessionStorage(latitude, longitude);
  }
}, [coords]);

  
  return (
    <>
      <div>
        <BrowserRouter>
          {/* {subscriptionRequestModal && token && (
            <div>
              <VarificationModal />
            </div>
          )} */}
          <Router />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
