import React, { useState } from "react";
import EMICalculator from "./EMICalculator";
import CapitalGainsTaxCalculator from "./CapitalGainTaxCalculator";

const TabComponent = () => {


  const [activeTab, setActiveTab] = useState("capital-gains");

  
  return (
    <div className="container my-5">
      <ul className="nav nav-tabs" role="tablist">
      <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "capital-gains" ? "active" : ""}`}
            onClick={() => setActiveTab("capital-gains")}
          >
            Capital Gains Tax
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "emi" ? "active" : ""}`}
            onClick={() => setActiveTab("emi")}
          >
            EMI Calculator
          </button>
        </li>
        
      </ul>
      <div className="tab-content">
        {activeTab === "capital-gains" && <CapitalGainsTaxCalculator />}
        {activeTab === "emi" && <EMICalculator />}
      
      </div>
    </div>
  );
};

export default TabComponent;
